.box {
  width:200px;
  height:100px;
  overflow:hidden;
  border-radius:20px;
  position:relative;
  z-index:0;
}

.box:after {
  content:"";
  position:absolute;
  top:10px;
  left:10px;
  right:10px;
  bottom:10px;
  background:#fff;
  border-radius:10px;
}

.box:before{
  content:"";
  position:absolute;
  top:-50%;
  left:-50%;
  right:-50%;
  bottom:-50%;
  clip-path:polygon(0 0,0 100%,100% 0, 100% 100%);
  animation:rotate 1s linear infinite;
}

@keyframes rotate {
  50% {
    transform:rotate(90deg) scaleX(0.5) ;
  }
  100% {
    transform:rotate(180deg);
  }
}
