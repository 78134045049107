.snowflake {
  color: #e7f5ff;
  font-size: 1.5em;
  font-weight: bold;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 1px #FFF;
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%
  }
  100% {
    top: 100%
  }
}

@-webkit-keyframes snowflakes-shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px)
  }
}

@keyframes snowflakes-fall {
  0% {
    top: -10%
  }
  100% {
    top: 100%
  }
}

@keyframes snowflakes-shake {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes snowflakes-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}

.snowflake {
  position: fixed;
  top: -10%;
  user-select: none;
  opacity: 0.8;
  cursor: default;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;

  .rotate {
    user-select: none;
    cursor: default;
    animation-name: snowflakes-rotate;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: running
  }
}

@for $i from 1 through 20 {
  .snowflake:nth-child(#{$i}) {
    --left-ini: percentage(random(100) / 100);
    --left-end: percentage(random(100) / 100);
    left: percentage(random(100) / 100);
    animation-delay: #{random() * 40}s, #{random() * 5}s;
  }
}

//.snowflake:nth-of-type(0) {
//  left: 1%;
//  -webkit-animation-delay: 0s, 0s;
//  animation-delay: 0s, 0s
//}
//
//.snowflake:nth-of-type(1) {
//  left: 10%;
//  -webkit-animation-delay: 1s, 1s;
//  animation-delay: 1s, 1s
//}
//
//.snowflake:nth-of-type(2) {
//  left: 20%;
//  -webkit-animation-delay: 6s, .5s;
//  animation-delay: 6s, .5s
//}
//
//.snowflake:nth-of-type(3) {
//  left: 30%;
//  -webkit-animation-delay: 4s, 2s;
//  animation-delay: 4s, 2s
//}
//
//.snowflake:nth-of-type(4) {
//  left: 40%;
//  -webkit-animation-delay: 2s, 2s;
//  animation-delay: 2s, 2s
//}
//
//.snowflake:nth-of-type(5) {
//  left: 50%;
//  -webkit-animation-delay: 8s, 3s;
//  animation-delay: 8s, 3s
//}
//
//.snowflake:nth-of-type(6) {
//  left: 60%;
//  -webkit-animation-delay: 6s, 2s;
//  animation-delay: 6s, 2s
//}
//
//.snowflake:nth-of-type(7) {
//  left: 70%;
//  -webkit-animation-delay: 2.5s, 1s;
//  animation-delay: 2.5s, 1s
//}
//
//.snowflake:nth-of-type(8) {
//  left: 80%;
//  -webkit-animation-delay: 1s, 0s;
//  animation-delay: 1s, 0s
//}
//
//.snowflake:nth-of-type(9) {
//  left: 90%;
//  -webkit-animation-delay: 3s, 1.5s;
//  animation-delay: 3s, 1.5s
//}
//
//.snowflake:nth-of-type(10) {
//  left: 25%;
//  -webkit-animation-delay: 2s, 0s;
//  animation-delay: 2s, 0s
//}
//
//.snowflake:nth-of-type(11) {
//  left: 65%;
//  -webkit-animation-delay: 4s, 2.5s;
//  animation-delay: 4s, 2.5s
//}
