.carousel-root, .carousel-slider, .slider-wrapper, .slider, .slide, .slide {
    height: 100%;
    width: 100%;
}

.expended .carousel-root {
    .carousel:nth-child(2) {
        left: 0;

        .thumbs-wrapper {
            background: #000;
        }
    }
}

.carousel-root {
    .carousel:nth-child(2) {
        position: relative;
        left: -20px;
        top: -200px;

        .thumbs-wrapper {
            border-radius: 8px;
            background: #FFF;

            .thumb {
                cursor: pointer;

                &.selected {
                    border: 3px solid #1976d2;
                }

                &:hover {
                    border: 3px solid #1976d2;
                }
            }
        }

        .control-arrow {
            background: #1976d2 !important;
            color: #000 !important;
            width: 32px !important;
            height: 32px !important;
            padding-top: 5px !important;
            padding-left: 8px !important;
            border-radius: 20px !important;
            top: calc(50% + 10px) !important;
            transform: translateY(-50%) !important;
        }
    }

    .carousel-slider {
        top: unset;

        .slider-wrapper {
            height: calc(100% - 200px);
        }

        .control-arrow {
            background: #1976d2 !important;
            color: #000 !important;
            height: 80px !important;
            top: calc(50% - 100px) !important;
            transform: translateY(-50%) !important;
        }
    }
}

.slide {
    display: flex;
    align-items: center;
    justify-content: center;
}



.dot {
    background: rgb(25, 118, 210) !important;
}
