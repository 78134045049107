*,
*::before,
*::after {
    box-sizing: border-box;
    transition: background-color 0.3s, color 0.3s;
}

html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: fixed;
    font-family: Roboto, sans-serif;
}

